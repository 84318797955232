import React, { useState } from "react"
import { Link } from 'gatsby'
import Logo from '../images/darkroom.svg'
import Button from '../images/open.svg'

const Sidebar = () => {
  const initsidebar = "closed"
  const [sidebar, setSidebar] = useState(initsidebar)

  function openSidebar() {
    if (sidebar === "closed") {
      setSidebar("openhover")
    } else {
      setSidebar("closed")
    }
  }

  return(
    <div className={`sidebar`}>
      <div className={`${sidebar}`}>
      <button id="openButton" onClick={openSidebar}><img src={Button} alt="+"/></button>
      <Link to="/">
        <img src={Logo} alt="Darkroom" />
        <span>DARKROOM</span>
      </Link>
      <div className="navlinks">
        <Link to="/">HOME</Link>
        <Link to="/projects">PROJECTS</Link>
        <Link to="/rss.xml">RSS FEED</Link>
        <Link to="/shop">SHOP</Link>
      </div>

      </div>
    </div>
  )
}

export default Sidebar
