import React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
  return(
    <div className="footer">
      <span><b>Darkroom Laboratory</b><br></br>Copyright © 2020 All Rights Reserved</span>
      <div className="flex">
        <p><Link to="/terms">Terms-Conditions</Link></p>
        <p><Link to="/privacy">Privacy-Policy</Link></p>
        <p><Link to="/contact">Contact</Link></p>
      </div>
    </div>
  )
}

export default Footer