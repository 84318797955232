import React from "react"
import Sidebar from "./sidebar"
import Footer from "./footer"

import "../styles/main.scss"

const Layout = ({ children }) => {
  return (
    <main>
      <Sidebar/>
      {/* + */}
      <div className="mainbody container">
        {children}
      </div>
      {/* + */}
      <Footer />
    </main>
  )
}

export default Layout
