import React from 'react'
import Layout from '../components/layout'

import Youtube from '../images/projects/youtube.png'
import Dolus from '../images/projects/dolus.png'
import Wisp from '../images/projects/wisp.png'

const Projects = () => (
  <Layout>
    <div id="projects">
      <div className="projectCard">
        <div>
          <img src={Youtube} alt="Darkroom Lab Banner"/>
        </div>
        <div>
          <h1>Darkroom Laboratories YouTube Channel</h1>
          <p>Check out our official YouTube channel for in depth content about the articles that we post on this site. We are currently working on videos about self-mastery, technology, and science. Many new series will be created in the future.</p>
        </div>
        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCvnE4lB6lzDFQ5rGyZPmu8Q">EXPLORE</a>
      </div>
      <div className="projectCard">
        <div>
          <img src={Dolus} alt="Dolus Preview"/>
        </div>
        <div>
          <h1>Dolus - Casual Survival Arcade</h1>
          <p>Dolus is the first ever game created by Darkroom Lab. Designed to be an arcady time killer. Currently available for free on Google Play Store.</p>
        </div>
        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.DarkroomLaboratory.Dolus&hl=en_us">EXPLORE</a>
      </div>
      <div className="projectCard">
        <div>
          <img src={Wisp} alt="Wisp Preview"/>
        </div>
        <div>
          <h1>Project Wisp</h1>
          <p>Darkroom Lab took part in GMTK2020 and created a game called Project Wisp, a puzzle/platformer with mechanics that may make one's head spin.</p>
        </div>
        <a target="_blank" rel="noopener noreferrer" href="https://darkroomlab.itch.io/wisp-gmtk2020">EXPLORE</a>
      </div>
    </div>
  </Layout>
)

export default Projects 